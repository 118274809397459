import { ReactFCC } from 'types/react';
import { LocalizationProvider, AdapterDateFns } from '@lux-ds/date-picker';
import NotificationProvider from '@lux-ds/notification';
import { LuxTheme } from '@lux-ds/theming';
import { GlobalStyles, ThemeProvider } from '@@utils/styles';

const BaseConfiguration: ReactFCC = ({ children }) => (
  <ThemeProvider theme={LuxTheme}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <NotificationProvider>{children}</NotificationProvider>
    </LocalizationProvider>
    <GlobalStyles />
  </ThemeProvider>
);

export default BaseConfiguration;
