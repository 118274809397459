import { createContext } from 'react';
import { Client, IConfig } from '@inbound/api';
import { useNavigate } from 'react-router-dom';
import { ReactFCC } from 'types/react';
import useAuth from '@@hooks/useAuth';
import ERoute from '@@setup/constants/ERoute';

const ApiContext = createContext(new Client({} as IConfig));

const ApiProvider: ReactFCC = ({ children }) => {
  const navigate = useNavigate();
  const { getToken } = useAuth();

  const errorHandler = (response: Response) => {
    if (response.status === 401) {
      navigate(ERoute.Logout);
    }
  };

  const getHeaders = () => ({
    Authorization: getToken(),
  });

  const api = new Client({ errorHandler, getHeaders }, process.env.REACT_APP_API_URL);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

const ApiConsumer = ApiContext.Consumer;

export { ApiConsumer, ApiProvider };

export default ApiContext;
