import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import SigninCallback from './components/SigninCallback';
import SilentRenew from './components/SilentRenew';
import ERoute from './constants/ERoute';
import PrivateRoutes from './PrivateRoutes';

const AppRouter: React.FC = () => (
  <Router>
    <Routes>
      <Route path={ERoute.Login} element={<Login />} />

      <Route path={ERoute.SigninCallback} element={<SigninCallback />} />

      <Route path={ERoute.SilentRenew} element={<SilentRenew />} />

      <Route path="*" element={<PrivateRoutes />} />
    </Routes>
  </Router>
);

export default AppRouter;
