import { AuthProvider } from '@@contexts/AuthContext';
import AppRouter from '@@setup/AppRouter';
import BaseConfiguration from '@@setup/BaseConfiguration';

const App: React.FC = () => (
  <BaseConfiguration>
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  </BaseConfiguration>
);

export default App;
