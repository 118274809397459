import { createContext, useCallback, useEffect, useState } from 'react';
import { Log, UserManager } from 'oidc-client';
import { ReactFCC } from 'types/react';
import EPermission from '@@setup/constants/EPermission';
import ERole from '@@setup/constants/ERole';

Log.logger = console;
Log.level = Log.DEBUG;

const EVENT_LOGOUT_DISPATCH_KEY = 'LUX_CORE_LOGOUT_TRIGGER';

const getToken = () => {
  const userSecurityDataStorageKey = `oidc.user:${process.env.REACT_APP_STS_AUTHORITY}:${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;

  const userSecurityData = sessionStorage.getItem(userSecurityDataStorageKey);

  if (userSecurityData) {
    const { access_token, token_type } = JSON.parse(userSecurityData);

    if (access_token) {
      return `${token_type} ${access_token}`;
    }
  }
  return '';
};

const userManager = new UserManager({
  automaticSilentRenew: true,
  authority: process.env.REACT_APP_STS_AUTHORITY,
  client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
  post_logout_redirect_uri: process.env.REACT_APP_STS_AUTHORITY,
  redirect_uri: `${process.env.REACT_APP_GOOGLE_CLIENT_ROOT}signin-callback`,
  response_type: 'code',
  scope: process.env.REACT_APP_GOOGLE_SCOPE,
  silent_redirect_uri: `${process.env.REACT_APP_GOOGLE_CLIENT_ROOT}silent-renew`,
  validateSubOnSilentRenew: true,
});

interface IUserProfile {
  email?: string;
}

interface IAuthContext {
  getToken: () => string;
  isSeniorUser: boolean;
  loading: boolean;
  login: () => void;
  logout: () => void;
  signedIn: boolean;
  signinRedirect: () => void;
  silentRenew: () => void;
  token?: string;
  userManager: UserManager;
  userPermissions: EPermission[];
  userProfile: IUserProfile;
  userRoles: ERole[];
}

const AuthContext = createContext<IAuthContext>({
  getToken,
  isSeniorUser: false,
  loading: true,
  login: () => void 0,
  logout: () => void 0,
  signedIn: false,
  signinRedirect: () => void 0,
  silentRenew: () => void 0,
  userManager: userManager,
  userProfile: {},
  userPermissions: [],
  userRoles: [],
});

const AuthProvider: ReactFCC = ({ children }) => {
  const [state, setState] = useState<
    Omit<IAuthContext, 'login' | 'logout' | 'signinRedirect' | 'silentRenew'>
  >({
    getToken,
    isSeniorUser: false,
    loading: true,
    signedIn: false,
    userManager,
    userPermissions: [],
    userProfile: {},
    userRoles: [],
  });

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await userManager.getUser();

        if (user) {
          setState(previousState => ({
            ...previousState,
            isSeniorUser: user.profile.role.some((role: ERole) =>
              [ERole.InventoryAdmin, ERole.InventorySenior].includes(role),
            ),
            loading: false,
            signedIn: true,
            userPermissions: [].concat(user.profile.inventoryPermissions),
            userProfile: {
              email: user.profile.email,
            },
            userRoles: user.profile.role,
          }));
        } else {
          setState(previousState => ({
            ...previousState,
            loading: false,
          }));

          throw new Error('User not found');
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    getUser();
  }, []);

  const login = useCallback(() => {
    const loginCallback = async () => {
      try {
        const redirectUrl = window.history.state?.usr?.redirectUrl;

        await userManager.signinRedirect({
          ...(redirectUrl && { state: { redirectUrl } }),
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    loginCallback();
  }, []);

  const logout = useCallback(() => {
    const logoutCallback = async () => {
      try {
        await userManager.removeUser();
        await userManager.signoutRedirect();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    logoutCallback();
  }, []);

  useEffect(() => {
    document.addEventListener(EVENT_LOGOUT_DISPATCH_KEY, logout);

    return () => {
      document.removeEventListener(EVENT_LOGOUT_DISPATCH_KEY, logout);
    };
  }, [logout]);

  const signinRedirect = useCallback(() => {
    const signinRedirectCallback = async () => {
      try {
        const { state } = await userManager.signinRedirectCallback();

        window.location.href = state?.redirectUrl || '/';
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    signinRedirectCallback();
  }, []);

  const silentRenew = useCallback(() => {
    const silentRenewCallback = async () => {
      try {
        await userManager.signinSilentCallback();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    silentRenewCallback();
  }, []);

  return (
    <AuthContext.Provider
      value={{ ...state, getToken, login, logout, signinRedirect, silentRenew }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthConsumer = AuthContext.Consumer;

export { AuthConsumer, AuthProvider };

export default AuthContext;
