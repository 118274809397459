import { useCallback, useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ApiProvider } from '@@contexts/ApiContext';
import { ContentProvider } from '@@contexts/ContentContext';
import useAuth from '@@hooks/useAuth';
import Logout from './components/Logout';
import ERole from './constants/ERole';
import ERoute from './constants/ERoute';
import RouteItems from './routes/RouteItems';

const LUX_CORE_ROUTE_CHANGE = 'LUX_CORE_ROUTE_TRIGGER';

interface IUserRoutes {
  element: JSX.Element;
  path: string;
  roles: ERole[];
}

const routes: IUserRoutes[] = [
  {
    element: <RouteItems />,
    path: `${ERoute.Items}/*`,
    roles: Object.values(ERole),
  },
];

const PrivateRoutes: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { signedIn, loading, userRoles } = useAuth();

  const handleCoreRouteChange = useCallback(
    (e: CustomEvent<{ path: string }>) => {
      navigate(e.detail.path);
    },
    [navigate],
  );

  useEffect(() => {
    document.addEventListener(LUX_CORE_ROUTE_CHANGE, handleCoreRouteChange as EventListener);

    return () => {
      document.removeEventListener(LUX_CORE_ROUTE_CHANGE, handleCoreRouteChange as EventListener);
    };
  }, [handleCoreRouteChange]);

  useEffect(() => {
    if (!loading && signedIn) {
      const hasAccess = Object.values(ERole).some(role => userRoles.includes(role));

      if (!hasAccess) {
        window.location.href = process.env.REACT_APP_STS_AUTHORITY as string;
      }
    }
  }, [loading, signedIn, userRoles]);

  const userRoutes = useMemo(
    () => routes.filter(route => route.roles.some(pathRole => userRoles.includes(pathRole))),
    [userRoles],
  );

  return loading ? null : signedIn ? (
    <ContentProvider>
      <ApiProvider>
        <Routes>
          <Route path={ERoute.Logout} element={<Logout />} />

          {userRoutes.map(({ element, path }) => (
            <Route key={path} path={path} element={element} />
          ))}

          <Route path="*" element={<Navigate to={ERoute.Items} />} />
        </Routes>
      </ApiProvider>
    </ContentProvider>
  ) : (
    <Navigate state={{ redirectUrl: location.pathname }} to={ERoute.Login} />
  );
};

export default PrivateRoutes;
