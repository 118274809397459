import useAuth from '@@hooks/useAuth';

const Logout: React.FC = () => {
  const { logout } = useAuth();

  logout();

  return null;
};

export default Logout;
