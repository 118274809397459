import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { InventoryGetCountersListModel } from '@inbound/api';
import { ReactFCC } from 'types/react';
import {
  GridSelectionModel,
  GridSortModel,
  IFilterValue,
  IMultipleSelectOptionIdName,
  IMultipleSelectOptionKeyValue,
} from '@lux-ds/data-grid';
import { useNotification } from '@lux-ds/notification';
import useApi from '@@hooks/useApi';
import useContent from '@@hooks/useContent';
import { IItemAggregator, IItemSubStatus } from '@@pages/item-list/constants/ItemStatus';

type IFilterOptions = {
  brand: IMultipleSelectOptionIdName[];
  businessModel: IMultipleSelectOptionKeyValue[];
  category: IMultipleSelectOptionIdName[];
  inclusion: IMultipleSelectOptionIdName[];
  itemTag: IMultipleSelectOptionIdName[];
  rating: IMultipleSelectOptionKeyValue[];
  supplier: IMultipleSelectOptionKeyValue[];
  warehouse: IMultipleSelectOptionIdName[];
};

type IQuickFilter = keyof InventoryGetCountersListModel | '';
type IAggregatorFilter = keyof IItemAggregator | '';
type ISubStatusFilter = keyof IItemSubStatus;

interface IItemsContext {
  aggregatorFilter: IAggregatorFilter;
  allRowsSelected: boolean;
  filterOptions: IFilterOptions;
  filters: IFilterValue;
  getFilterOptions: () => void;
  getItemTagsOptions: () => void;
  onAggregatorFilterChange: (
    event: React.SyntheticEvent<Element, Event>,
    aggregatorFilter: string,
  ) => void;
  onPipelinePrioritizationFilterChange: (pipelinePrioritizationFilter: string[]) => void;
  onPipelineStatusFilterChange: (pipelineStatusFilter: string) => void;
  onSubStatusFilterChange: (subStatusFilter: string) => void;
  page: number;
  pageSize: number;
  pipelineStatusFilter: string;
  pipelinePrioritizationFilter: string[];
  quickFilter: IQuickFilter[];
  search: string;
  selectionModel: GridSelectionModel;
  setAllRowsSelected: (allRowsSelected: boolean) => void;
  setFilters: (filters: IFilterValue) => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  // setQuickFilter: (event: React.SyntheticEvent<Element, Event>, quickFilter: string) => void;
  setSearch: (search: string) => void;
  setSelectionModel: (selectionModel: GridSelectionModel) => void;
  setSortModel: (sortModel: GridSortModel) => void;
  sortModel: GridSortModel;
  subStatusFilters: ISubStatusFilter[];
}

const filterOptionsDefaultValue: IFilterOptions = {
  brand: [],
  businessModel: [],
  category: [],
  inclusion: [],
  itemTag: [],
  rating: [],
  supplier: [],
  warehouse: [],
};

const ItemsContext = createContext<IItemsContext>({
  aggregatorFilter: '',
  allRowsSelected: false,
  filterOptions: filterOptionsDefaultValue,
  filters: {},
  getFilterOptions: () => void 0,
  getItemTagsOptions: () => void 0,
  onAggregatorFilterChange: () => void 0,
  onPipelinePrioritizationFilterChange: () => void 0,
  onPipelineStatusFilterChange: () => void 0,
  onSubStatusFilterChange: () => void 0,
  page: 0,
  pageSize: 25,
  pipelinePrioritizationFilter: [],
  pipelineStatusFilter: '',
  quickFilter: [],
  search: '',
  selectionModel: [],
  setAllRowsSelected: () => void 0,
  setFilters: () => void 0,
  setPage: () => void 0,
  setPageSize: () => void 0,
  setSearch: () => void 0,
  setSelectionModel: () => void 0,
  setSortModel: () => void 0,
  sortModel: [],
  subStatusFilters: [],
});

const ItemsProvider: ReactFCC = ({ children }) => {
  const api = useApi();
  const {
    content: { itemList: content },
  } = useContent();
  const { enqueueNotification } = useNotification();
  const [allRowsSelected, setAllRowsSelected] = useState<IItemsContext['allRowsSelected']>(false);
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>(filterOptionsDefaultValue);
  const [filters, setFilters] = useState<IItemsContext['filters']>({});
  const [page, setPage] = useState<IItemsContext['page']>(0);
  const [pageSize, setPageSize] = useState<IItemsContext['pageSize']>(25);
  const [search, setSearch] = useState<IItemsContext['search']>('');
  const [selectionModel, setSelectionModel] = useState<IItemsContext['selectionModel']>([]);
  const [sortModel, setSortModel] = useState<IItemsContext['sortModel']>([]);

  const [aggregatorFilter, setAggregatorFilter] = useState<IItemsContext['aggregatorFilter']>('');
  const [pipelineStatusFilter, setPipelineStatusFilter] =
    useState<IItemsContext['pipelineStatusFilter']>('');
  const [subStatusFilters, setSubStatusFilters] = useState<IItemsContext['subStatusFilters']>([]);
  const [pipelinePrioritizationFilter, setPipelinePrioritizationFilter] = useState<
    IItemsContext['pipelinePrioritizationFilter']
  >([]);

  const quickFilter = useMemo(
    () => [aggregatorFilter, pipelineStatusFilter, ...subStatusFilters] as IQuickFilter[],
    [aggregatorFilter, pipelineStatusFilter, subStatusFilters],
  );

  const handleSetPipelinePrioritizationFilter = useCallback(
    (prioritizationFilter: string[]) => setPipelinePrioritizationFilter(prioritizationFilter),
    [],
  );

  const handleSetFilters = useCallback((filters: IFilterValue) => setFilters(filters), []);

  const handleSetPage = useCallback((page: number) => setPage(page), []);

  const handleSetPageSize = useCallback((pageSize: number) => setPageSize(pageSize), []);

  const handleSetSearch = useCallback((search: string) => setSearch(search), []);

  const handleSetAllRowsSelected = useCallback(
    (allRowsSelected: boolean) => setAllRowsSelected(allRowsSelected),
    [],
  );

  const handleSetSelectionModel = useCallback(
    (selectionModel: GridSelectionModel) => setSelectionModel(selectionModel),
    [],
  );

  const handleSetSortModel = useCallback((sortModel: GridSortModel) => setSortModel(sortModel), []);

  const getFilterOptions = useCallback(() => {
    const getFilterOptionsCallback = async () => {
      try {
        const [brand, businessModel, category, inclusion, itemTag, rating, supplier, warehouse] =
          await Promise.all([
            api.productBrands_GetAll(),
            api.products_GetBusinessModels(),
            api.categories_GetAll(),
            api.productInclusions_GetAll(),
            api.products_GetTagsV2(),
            api.rating_GetRatings(),
            api.products_GetSuppliers(),
            api.products_GetWarehouses(),
          ]);

        const filters: IFilterOptions = {
          brand,
          businessModel,
          category,
          inclusion,
          itemTag,
          rating,
          supplier,
          warehouse: warehouse.map(({ id, name }) => ({ id, name })),
        } as IFilterOptions;

        setFilterOptions(filters);
      } catch {
        enqueueNotification({ title: content.errorGetFilters }, { variant: 'error' });
      }
    };

    getFilterOptionsCallback();
  }, [api, content, enqueueNotification]);

  const getItemTagsOptions = useCallback(() => {
    const getItemTagsOptionsCallback = async () => {
      try {
        const itemTags = await api.products_GetTagsV2();

        setFilterOptions(previous => ({
          ...previous,
          itemTag: itemTags as IFilterOptions['itemTag'],
        }));
      } catch {
        enqueueNotification({ title: content.errorGetItemTags }, { variant: 'error' });
      }
    };

    getItemTagsOptionsCallback();
  }, [api, content, enqueueNotification]);

  useEffect(() => {
    handleSetSelectionModel([]);
    handleSetPage(0);
  }, [filters, handleSetPage, handleSetSelectionModel, quickFilter, search, sortModel]);

  const handleAggregatorFilterChange = useCallback(
    (_: React.SyntheticEvent<Element, Event>, aggregatorFilter: string) => {
      setAggregatorFilter(aggregatorFilter as IAggregatorFilter);
      setSubStatusFilters([]);
    },
    [],
  );

  const handleSubStatusFilterChange = useCallback(
    (subStatusFilter: string) => {
      if (subStatusFilters.includes(subStatusFilter as ISubStatusFilter)) {
        setSubStatusFilters(prevState => prevState.filter(status => status !== subStatusFilter));
      } else {
        setSubStatusFilters(prevState => [...prevState, subStatusFilter as ISubStatusFilter]);
      }
    },
    [subStatusFilters],
  );

  const handlePipelineStatusFilterChange = useCallback(
    (pipelineStatusFilter: string) =>
      setPipelineStatusFilter(prevState =>
        prevState === pipelineStatusFilter ? '' : pipelineStatusFilter,
      ),
    [],
  );

  useEffect(() => {
    setPipelineStatusFilter('');
    setSubStatusFilters([]);
  }, [aggregatorFilter]);

  useEffect(() => {
    setPipelinePrioritizationFilter([]);
  }, [aggregatorFilter, subStatusFilters, pipelineStatusFilter]);

  return (
    <ItemsContext.Provider
      value={{
        aggregatorFilter,
        allRowsSelected,
        filterOptions,
        filters,
        getFilterOptions,
        getItemTagsOptions,
        onAggregatorFilterChange: handleAggregatorFilterChange,
        onPipelineStatusFilterChange: handlePipelineStatusFilterChange,
        onSubStatusFilterChange: handleSubStatusFilterChange,
        onPipelinePrioritizationFilterChange: handleSetPipelinePrioritizationFilter,
        page,
        pageSize,
        pipelinePrioritizationFilter,
        pipelineStatusFilter,
        quickFilter,
        search,
        selectionModel,
        setAllRowsSelected: handleSetAllRowsSelected,
        setFilters: handleSetFilters,
        setPage: handleSetPage,
        setPageSize: handleSetPageSize,
        setSearch: handleSetSearch,
        setSelectionModel: handleSetSelectionModel,
        setSortModel: handleSetSortModel,
        sortModel,
        subStatusFilters,
      }}
    >
      {children}
    </ItemsContext.Provider>
  );
};

const ItemsConsumer = ItemsContext.Consumer;

export { ItemsConsumer, ItemsProvider };

export default ItemsContext;
