import useAuth from '@@hooks/useAuth';

const SilentRenew: React.FC = () => {
  const { silentRenew } = useAuth();

  silentRenew();

  return null;
};

export default SilentRenew;
