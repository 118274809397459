import { createContext, useCallback, useEffect, useState } from 'react';
import { InventoryGetCountersListModel } from '@inbound/api';
import {
  GridSelectionModel,
  GridSortModel,
  IFilterValue,
  IMultipleSelectOptionIdName,
  IMultipleSelectOptionKeyValue,
} from '@lux-ds/data-grid';
import { useNotification } from '@lux-ds/notification';
import useApi from '@@hooks/useApi';
import useContent from '@@hooks/useContent';

type IFilterOptions = {
  brand: IMultipleSelectOptionIdName[];
  businessModel: IMultipleSelectOptionKeyValue[];
  category: IMultipleSelectOptionIdName[];
  inclusion: IMultipleSelectOptionIdName[];
  itemTag: IMultipleSelectOptionIdName[];
  rating: IMultipleSelectOptionKeyValue[];
  supplier: IMultipleSelectOptionKeyValue[];
  warehouse: IMultipleSelectOptionIdName[];
};

type IQuickFilter = keyof InventoryGetCountersListModel | '';

interface IItemsContextOld {
  allRowsSelected: boolean;
  filterOptions: IFilterOptions;
  filters: IFilterValue;
  getFilterOptions: () => void;
  getItemTagsOptions: () => void;
  page: number;
  pageSize: number;
  quickFilter: IQuickFilter;
  search: string;
  selectionModel: GridSelectionModel;
  setAllRowsSelected: (allRowsSelected: boolean) => void;
  setFilters: (filters: IFilterValue) => void;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  setQuickFilter: (event: React.SyntheticEvent<Element, Event>, quickFilter: string) => void;
  setSearch: (search: string) => void;
  setSelectionModel: (selectionModel: GridSelectionModel) => void;
  setSortModel: (sortModel: GridSortModel) => void;
  sortModel: GridSortModel;
}

const filterOptionsDefaultValue: IFilterOptions = {
  brand: [],
  businessModel: [],
  category: [],
  inclusion: [],
  itemTag: [],
  rating: [],
  supplier: [],
  warehouse: [],
};

const ItemsContextOld = createContext<IItemsContextOld>({
  allRowsSelected: false,
  filterOptions: filterOptionsDefaultValue,
  filters: {},
  getFilterOptions: () => void 0,
  getItemTagsOptions: () => void 0,
  page: 0,
  pageSize: 25,
  quickFilter: '',
  search: '',
  selectionModel: [],
  setAllRowsSelected: () => void 0,
  setFilters: () => void 0,
  setPage: () => void 0,
  setPageSize: () => void 0,
  setQuickFilter: () => void 0,
  setSearch: () => void 0,
  setSelectionModel: () => void 0,
  setSortModel: () => void 0,
  sortModel: [],
});

const ItemsProviderOld: React.FC = ({ children }) => {
  const api = useApi();
  const {
    content: { itemList: content },
  } = useContent();
  const { enqueueNotification } = useNotification();
  const [allRowsSelected, setAllRowsSelected] =
    useState<IItemsContextOld['allRowsSelected']>(false);
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>(filterOptionsDefaultValue);
  const [filters, setFilters] = useState<IItemsContextOld['filters']>({});
  const [page, setPage] = useState<IItemsContextOld['page']>(0);
  const [pageSize, setPageSize] = useState<IItemsContextOld['pageSize']>(25);
  const [quickFilter, setQuickFilter] = useState<IItemsContextOld['quickFilter']>('');
  const [search, setSearch] = useState<IItemsContextOld['search']>('');
  const [selectionModel, setSelectionModel] = useState<IItemsContextOld['selectionModel']>([]);
  const [sortModel, setSortModel] = useState<IItemsContextOld['sortModel']>([]);

  const handleSetFilters = useCallback((filters: IFilterValue) => setFilters(filters), []);

  const handleSetPage = useCallback((page: number) => setPage(page), []);

  const handleSetPageSize = useCallback((pageSize: number) => setPageSize(pageSize), []);

  const handleSetQuickFilter = useCallback(
    (_, quickFilter: string) => setQuickFilter(quickFilter as IQuickFilter),
    [],
  );

  const handleSetSearch = useCallback((search: string) => setSearch(search), []);

  const handleSetAllRowsSelected = useCallback(
    (allRowsSelected: boolean) => setAllRowsSelected(allRowsSelected),
    [],
  );

  const handleSetSelectionModel = useCallback(
    (selectionModel: GridSelectionModel) => setSelectionModel(selectionModel),
    [],
  );

  const handleSetSortModel = useCallback((sortModel: GridSortModel) => setSortModel(sortModel), []);

  const getFilterOptions = useCallback(() => {
    const getFilterOptionsCallback = async () => {
      try {
        const [brand, businessModel, category, inclusion, itemTag, rating, supplier, warehouse] =
          await Promise.all([
            api.productBrands_GetAll(),
            api.products_GetBusinessModels(),
            api.categories_GetAll(),
            api.productInclusions_GetAll(),
            api.products_GetTagsV2(),
            api.rating_GetRatings(),
            api.products_GetSuppliers(),
            api.products_GetWarehouses(),
          ]);

        const filters: IFilterOptions = {
          brand,
          businessModel,
          category,
          inclusion,
          itemTag,
          rating,
          supplier,
          warehouse: warehouse.map(({ id, name }) => ({ id, name })),
        } as IFilterOptions;

        setFilterOptions(filters);
      } catch {
        enqueueNotification({ title: content.errorGetFilters }, { variant: 'error' });
      }
    };

    getFilterOptionsCallback();
  }, [api, content, enqueueNotification]);

  const getItemTagsOptions = useCallback(() => {
    const getItemTagsOptionsCallback = async () => {
      try {
        const itemTags = await api.products_GetTagsV2();

        setFilterOptions(previous => ({
          ...previous,
          itemTag: itemTags as IFilterOptions['itemTag'],
        }));
      } catch {
        enqueueNotification({ title: content.errorGetItemTags }, { variant: 'error' });
      }
    };

    getItemTagsOptionsCallback();
  }, [api, content, enqueueNotification]);

  useEffect(() => {
    handleSetSelectionModel([]);
    handleSetPage(0);
  }, [filters, handleSetPage, handleSetSelectionModel, quickFilter, search, sortModel]);

  return (
    <ItemsContextOld.Provider
      value={{
        allRowsSelected,
        filterOptions,
        filters,
        getFilterOptions,
        getItemTagsOptions,
        page,
        pageSize,
        quickFilter,
        search,
        selectionModel,
        setAllRowsSelected: handleSetAllRowsSelected,
        setFilters: handleSetFilters,
        setPage: handleSetPage,
        setPageSize: handleSetPageSize,
        setQuickFilter: handleSetQuickFilter,
        setSearch: handleSetSearch,
        setSelectionModel: handleSetSelectionModel,
        setSortModel: handleSetSortModel,
        sortModel,
      }}
    >
      {children}
    </ItemsContextOld.Provider>
  );
};

const ItemsConsumer = ItemsContextOld.Consumer;

export { ItemsConsumer, ItemsProviderOld };

export default ItemsContextOld;
