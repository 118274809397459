import useAuth from '@@hooks/useAuth';

const Login: React.FC = () => {
  const { login } = useAuth();

  login();

  return null;
};

export default Login;
