/**
 * This util will be discontinued and its logic passed onto the 'errorHandler'
 * interceptor we have for the '@inbound/api' when we standardize error handling
 * in the BE and refactor the 'errorHandler' that the '@inbound/api' receives
 * to include the 'processor' parameter.
 * We should evaluate the priority for this based on the number of requests
 * that use this util.
 */
import { HttpStatusCode, IApiException } from '@inbound/api';

// This interface is temporary until the standardization of error handling in
// the BE brings us the same exception type.
interface IValidationException {
  errors?: Record<string, string[]>;
  status?: HttpStatusCode;
  title?: string;
  traceId?: string;
  type?: string;
}

const formatApiValidationErrors = (error: unknown) => {
  let errorMessages: string | undefined;

  const { response, status } = error as { response: string; status: HttpStatusCode };

  if (status === 400 && response) {
    const { errors } = JSON.parse(response) as IApiException | IValidationException;

    if (errors) {
      if (!Array.isArray(errors)) {
        errorMessages = Object.values(errors)
          .flatMap(error => error)
          .reduce((result: string, error, index) => result + `${index + 1}: ${error}\n`, '');
      } else {
        errorMessages = errors.reduce(
          (result: string, { message }, index) => result + `${index + 1}: ${message}\n`,
          '',
        );
      }
    }
  }

  return errorMessages;
};

export default formatApiValidationErrors;
