import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ApplicationError,
  BaseResponseModelOfObject,
  EBulkActionTags,
  EBulkActionTags2,
  EItemInventoryStatus,
  EPipelinePrioritization,
  EPipelineStatus,
  GetProductsBulkUpdateBusinessModelV3,
  GetProductsBulkUpdateV2,
  GetProductsQueryResponseV2,
  GetProductsRequestV2,
  HttpStatusCode,
  IdNamePairGetModelOfNullableInteger,
  InventoryGetCountersListModel,
  InventoryGetCountersListModelV2,
  InventoryGetCountersPipelinesStatusModel,
} from '@inbound/api';
import { ReactFCC } from 'types/react';
import { IDateRangeValue, IMultipleSelectOptionIdName, IValueRangeValue } from '@lux-ds/filter';
import { useNotification } from '@lux-ds/notification';
import useApi from '@@hooks/useApi';
import useContent from '@@hooks/useContent';
import useFeatureFlag from '@@hooks/useFeatureFlag';
import useItemsContext from '@@hooks/useItemsContext';
import cleanObject from '@@utils/cleanObject';
import formatApiValidationErrors from '@@utils/formatApiValidationErrors';
import {
  itemOutOfStatus,
  itemPublishedStatus,
  itemStatusAggregators,
} from '../constants/ItemStatus';

interface IItemListContext {
  bspTemplateFileURL: string;
  bspValidationUploadMessage?: BaseResponseModelOfObject;
  changeItemBusinessModel: (businessModel: number, onSuccess: () => void) => void;
  changeItemSupplierReturn: (onSuccess: () => void) => void;
  changeItemTags: (
    action: EBulkActionTags2,
    tags: IdNamePairGetModelOfNullableInteger[],
    onSuccess: () => void,
  ) => void;
  counts: InventoryGetCountersListModelV2;
  hasOnlyInvalidItems: boolean;
  invalidItems: string[];
  isBspMassUpdateEnabled: boolean;
  isBspMassUpdateSubmitting: boolean;
  isBspMassUpdateValidated: boolean;
  isBspMassUpdateValidating: boolean;
  isSavingBusinessModel: boolean;
  isSavingItemTags: EBulkActionTags2 | null;
  isSavingSupplierReturn: boolean;
  items: GetProductsQueryResponseV2[];
  loading: boolean;
  onBspMassUpdateReset: () => void;
  onBspMassUpdateSubmit: (file: File, onSuccess?: () => void) => void;
  onBspMassUpdateValidate: (file: File) => void;
  pipelineCounts: Record<keyof InventoryGetCountersPipelinesStatusModel, number>;
  pipelineCounters: InventoryGetCountersPipelinesStatusModel;
  resetSupplierReturnValidation: () => void;
  totalItems: number;
  validateItemSupplierReturn: () => void;
}

const countsDefaultValueV2: InventoryGetCountersListModelV2 = {
  allItems: 0,
  available: 0,
  canceled: 0,
  consigned: 0,
  draft: 0,
  onHold: 0,
  ordered: 0,
  processed: 0,
  processing: 0,
  reserved: 0,
  sold: 0,
  supplierReturn: 0,
};

const pipelineDefaultValue: Record<keyof InventoryGetCountersPipelinesStatusModel, number> = {
  awaiting: 0,
  pricing: 0,
  productMatch: 0,
  review: 0,
};

const pipelineCountersDefaultValue: InventoryGetCountersPipelinesStatusModel = {
  awaiting: {},
  pricing: {},
  productMatch: {},
  review: {},
};

const countsDefaultValue: InventoryGetCountersListModel = {
  allItems: 0,
  productMatch: 0,
  inTransit: 0,
  delivered: 0,
  pendingProcessing: 0,
  processing: 0,
  awaiting: 0,
  pendingReview: 0,
  reviewed: 0,
  available: 0,
  disposedOf: 0,
  canceled: 0,
  supplierReturn: 0,
  soldBySupplier: 0,
  published: 0,
  unpublished: 0,
  sold: 0,
  reserved: 0,
  marketplace: 0,
  consigned: 0,
  draft: 0,
  pendingPricing: 0,
};

const ItemListContext = createContext<IItemListContext>({
  bspTemplateFileURL: '',
  changeItemBusinessModel: () => void 0,
  changeItemSupplierReturn: () => void 0,
  changeItemTags: () => void 0,
  counts: countsDefaultValueV2,
  hasOnlyInvalidItems: true,
  invalidItems: [],
  isBspMassUpdateEnabled: false,
  isBspMassUpdateSubmitting: false,
  isBspMassUpdateValidated: false,
  isBspMassUpdateValidating: false,
  isSavingBusinessModel: false,
  isSavingItemTags: null,
  isSavingSupplierReturn: false,
  items: [],
  loading: true,
  onBspMassUpdateReset: () => void 0,
  onBspMassUpdateSubmit: () => void 0,
  onBspMassUpdateValidate: () => void 0,
  pipelineCounts: pipelineDefaultValue,
  pipelineCounters: pipelineCountersDefaultValue,
  resetSupplierReturnValidation: () => void 0,
  totalItems: 0,
  validateItemSupplierReturn: () => void 0,
});

const ItemListProvider: ReactFCC = ({ children }) => {
  const isBspMassUpdateEnabled = useFeatureFlag('EnableBspMassUpdate');

  const api = useApi();
  const {
    content: { itemList: content },
  } = useContent();
  const {
    aggregatorFilter,
    allRowsSelected,
    filters,
    getFilterOptions,
    getItemTagsOptions,
    page,
    pageSize,
    pipelinePrioritizationFilter,
    pipelineStatusFilter,
    quickFilter,
    search,
    selectionModel,
    setPage,
    setSelectionModel,
    sortModel,
    subStatusFilters,
  } = useItemsContext();
  const { enqueueNotification } = useNotification();
  const [abortControllerGetItems, setAbortControllerGetItems] = useState<AbortController | null>(
    null,
  );

  const [counts, setCounts] = useState<IItemListContext['counts']>(countsDefaultValue);
  const [pipelineCounts, setPipelineCounts] =
    useState<IItemListContext['pipelineCounts']>(pipelineDefaultValue);

  const [pipelineCounters, setPipelineCounters] = useState<IItemListContext['pipelineCounters']>(
    pipelineCountersDefaultValue,
  );

  const [hasOnlyInvalidItems, setHasOnlyInvalidItems] =
    useState<IItemListContext['hasOnlyInvalidItems']>(true);
  const [isSavingBusinessModel, setIsSavingBusinessModel] =
    useState<IItemListContext['isSavingBusinessModel']>(false);
  const [isSavingSupplierReturn, setIsSavingSupplierReturn] =
    useState<IItemListContext['isSavingBusinessModel']>(false);
  const [isSavingItemTags, setIsSavingItemTags] =
    useState<IItemListContext['isSavingItemTags']>(null);
  const [items, setItems] = useState<IItemListContext['items']>([]);
  const [loading, setLoading] = useState<IItemListContext['loading']>(true);
  const [invalidItems, setInvalidItems] = useState<IItemListContext['invalidItems']>([]);
  const [totalItems, setTotalItems] = useState<IItemListContext['totalItems']>(0);

  const [isBspMassUpdateValidated, setIsBspMassUpdateValidated] =
    useState<IItemListContext['isBspMassUpdateValidated']>(false);
  const [isBspMassUpdateValidating, setIsBspMassUpdateValidating] =
    useState<IItemListContext['isBspMassUpdateValidating']>(false);
  const [isBspMassUpdateSubmitting, setIsBspMassUpdateSubmitting] =
    useState<IItemListContext['isBspMassUpdateSubmitting']>(false);
  const [bspValidationUploadMessage, setBspValidationUploadMessage] =
    useState<IItemListContext['bspValidationUploadMessage']>();

  const [bspTemplateFileURL, setBspTemplateFileURL] =
    useState<IItemListContext['bspTemplateFileURL']>('');

  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);

  const mappedFilters = useMemo(
    () =>
      Object.entries(filters).reduce(
        (
          result: Record<
            string,
            IDateRangeValue | IValueRangeValue | IMultipleSelectOptionIdName['id'][]
          >,
          [key, values],
        ) => ({
          ...result,
          [key]: Array.isArray(values)
            ? values.map(value => ('id' in value ? value.id : value.key))
            : values,
        }),
        {},
      ),
    [filters],
  );

  const getSubStatusFilters = useCallback(() => {
    if (subStatusFilters.length) {
      return subStatusFilters;
    } else if (aggregatorFilter === 'outOf') {
      return itemOutOfStatus;
    } else if (aggregatorFilter === 'published') {
      return itemPublishedStatus;
    }

    return [];
  }, [aggregatorFilter, subStatusFilters]);

  const statuses = useMemo(
    () =>
      [
        ['allItems', 'outOf', 'published'].includes(aggregatorFilter) ? null : aggregatorFilter,
        ...getSubStatusFilters(),
      ].filter(status => !!status) as unknown as EItemInventoryStatus[],
    [aggregatorFilter, getSubStatusFilters],
  );

  useEffect(() => {
    const getItemsCallback = async () => {
      try {
        setLoading(true);

        const mappedFilters = Object.entries(filters).reduce(
          (result, [key, values]) => ({
            ...result,
            [key]: Array.isArray(values)
              ? values.map(value => ('id' in value ? value.id : value.key))
              : values,
          }),
          {},
        );

        let { field: orderBy, sort: orderDirection } = sortModel[0] || {};

        if (!sortModel[0]) {
          if (pipelineStatusFilter === 'review') {
            orderBy = 'isRereview';
            orderDirection = 'desc';
          } else if (pipelineStatusFilter === 'productMatch') {
            orderBy = 'isProductMatchPriority';
            orderDirection = 'desc';
          }
        }

        const requestV2: GetProductsRequestV2 = {
          ...mappedFilters,
          orderBy,
          orderDirection: orderDirection || undefined,
          pageIndex: page,
          pageSize,
          pipelineStatuses: pipelineStatusFilter
            ? [pipelineStatusFilter as unknown as EPipelineStatus]
            : [],
          prioritizationStatuses: pipelinePrioritizationFilter.map(
            value => EPipelinePrioritization[value as unknown as EPipelinePrioritization],
          ) as unknown as EPipelinePrioritization[],
          search,
          statuses,
        };

        const {
          counts,
          items,
          pipelineStatusCounts,
          totalItems: currentItems,
        } = await api.products_GetAll2POST(cleanObject(requestV2), abortControllerGetItems?.signal);

        const currentCounts = counts as InventoryGetCountersListModelV2;

        const getCount = (keys: string[]) => {
          return keys.reduce(
            (res, curr) =>
              res + (currentCounts[curr as keyof InventoryGetCountersListModelV2] || 0),
            0,
          );
        };

        const statusCounts = [
          ...itemStatusAggregators,
          ...itemOutOfStatus,
          ...itemPublishedStatus,
        ].reduce((result, key) => {
          if (key === 'published') {
            return {
              ...result,
              [key]: getCount(itemPublishedStatus),
            };
          } else if (key === 'outOf') {
            return {
              ...result,
              [key]: getCount(itemOutOfStatus),
            };
          }

          return { ...result, [key]: currentCounts[key as keyof InventoryGetCountersListModelV2] };
        }, {});

        const pipelineCounts: Record<keyof InventoryGetCountersPipelinesStatusModel, number> =
          Object.entries(pipelineStatusCounts!).reduce(
            (result, [key, value]) => ({ ...result, [key]: value.value }),
            {},
          ) as Record<keyof InventoryGetCountersPipelinesStatusModel, number>;

        setCounts(statusCounts || countsDefaultValue);
        setPipelineCounters(pipelineStatusCounts || pipelineCountersDefaultValue);
        setPipelineCounts(pipelineCounts);
        setItems(items || []);
        setTotalItems(currentItems || 0);
        setLoading(false);
      } catch (error) {
        if ((error as DOMException).name !== 'AbortError') {
          setItems([]);
          setPage(0);
          setTotalItems(0);
          setLoading(false);

          enqueueNotification({ title: content.errorGetItems }, { variant: 'error' });
        }
      }
    };

    if (abortControllerGetItems) {
      getItemsCallback();
    }

    return () => abortControllerGetItems?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abortControllerGetItems, api, content, enqueueNotification, setPage]);

  const getItems = useCallback(() => setAbortControllerGetItems(new AbortController()), []);

  useEffect(() => {
    getItems();
  }, [
    getItems,
    mappedFilters,
    page,
    pageSize,
    pipelinePrioritizationFilter,
    quickFilter,
    search,
    sortModel,
  ]);

  const handleBspMassUpdateDownloadTemplate = useCallback(() => {
    const handleBspMassUpdateDownloadTemplateCallback = async () => {
      try {
        const url = await api.pricing_GetPricingItemTemplateFile();
        setBspTemplateFileURL(url);
      } catch {
        enqueueNotification({ title: content.bspDownloadTemplateError }, { variant: 'error' });
      }
    };

    handleBspMassUpdateDownloadTemplateCallback();
  }, [api, content.bspDownloadTemplateError, enqueueNotification]);

  useEffect(() => {
    if (isBspMassUpdateEnabled) {
      handleBspMassUpdateDownloadTemplate();
    }
  }, [isBspMassUpdateEnabled]);

  const handleMassUpdateValidationMessages = useCallback(
    ({ errors, warnings }: BaseResponseModelOfObject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const formatMessages = (messages: any[]): string[] =>
        messages.reduce((result, { message }) => [...result, message], []);

      setBspValidationUploadMessage({
        errors: formatMessages(errors as ApplicationError[]) as ApplicationError[],
        warnings: formatMessages(warnings as ApplicationError[]) as ApplicationError[],
      });
    },
    [],
  );

  const handleBspMassUpdateReset = useCallback(() => {
    setIsBspMassUpdateValidated(false);
    setBspValidationUploadMessage(undefined);
  }, []);

  const handleBspMassUpdateSubmit = useCallback(
    (file: File, onSuccess?: () => void) => {
      const handleBspMassUpdateSubmissionCallback = async () => {
        setIsBspMassUpdateSubmitting(true);

        try {
          await api.products_UpdateInBulkItemsCps(
            {
              data: file,
              fileName: file.name,
            },
            false,
          );

          enqueueNotification(
            {
              title: content.bspMassUpdateSuccess,
            },
            { variant: 'success' },
          );

          onSuccess?.();
        } catch {
          enqueueNotification(
            {
              title: content.bspMassUpdateSubmissionError,
            },
            { variant: 'error' },
          );
        } finally {
          setIsBspMassUpdateSubmitting(false);
        }
      };

      handleBspMassUpdateSubmissionCallback();
    },
    [api, content.bspMassUpdateSubmissionError, content.bspMassUpdateSuccess, enqueueNotification],
  );

  const handleBspMassUpdateValidate = useCallback(
    async (file: File) => {
      setIsBspMassUpdateValidating(true);

      try {
        const res = await api.products_UpdateInBulkItemsCps(
          {
            data: file,
            fileName: file.name,
          },
          true,
        );

        handleMassUpdateValidationMessages(res);

        if (!res.errors?.length) {
          setIsBspMassUpdateValidated(true);
        }
      } catch (error: unknown) {
        const { response } = error as {
          response: string;
          status: HttpStatusCode;
        };

        if (response) {
          handleMassUpdateValidationMessages(JSON.parse(response));
        }

        enqueueNotification(
          {
            title: content.bspMassUpdateValidationError,
          },
          { variant: 'error' },
        );
      } finally {
        setIsBspMassUpdateValidating(false);
      }
    },
    [
      api,
      content.bspMassUpdateValidationError,
      enqueueNotification,
      handleMassUpdateValidationMessages,
    ],
  );

  const handleValidateItemSupplierReturn = useCallback(() => {
    const handleValidateItemSupplierReturnCallback = async () => {
      try {
        // Add Feature Flag handler
        const { hasOnlyInvalidItems, invalidItems } =
          await api.products_UpdateInBulkItemsSupplierReturn2({
            toAllFilteredItems: allRowsSelected,
            toValidateItems: true,
            ...(allRowsSelected
              ? {
                  ...mappedFilters,
                  pipelineStatuses: pipelineStatusFilter
                    ? [pipelineStatusFilter as unknown as EPipelineStatus]
                    : [],
                  search,
                  statuses,
                }
              : {
                  itemIds: selectionModel as string[],
                }),
          });

        if (invalidItems?.length) {
          const messages = invalidItems?.map(({ sku }) => (sku as string).replace('\n', '')) || [];
          setInvalidItems(messages);
        }

        setHasOnlyInvalidItems(!!hasOnlyInvalidItems);
      } catch (error) {
        const errorMessages = formatApiValidationErrors(error);

        enqueueNotification(
          { content: errorMessages, title: content.validateItemSupplierReturnError },
          { variant: 'error' },
        );
      } finally {
        setIsSavingSupplierReturn(false);
      }
    };

    handleValidateItemSupplierReturnCallback();
  }, [
    allRowsSelected,
    api,
    content.validateItemSupplierReturnError,
    enqueueNotification,
    mappedFilters,
    pipelineStatusFilter,
    search,
    selectionModel,
    statuses,
  ]);

  const handleChangeItemSupplierReturn = useCallback(
    (onSuccess: () => void) => {
      const handleChangeItemSupplierReturnCallback = async () => {
        try {
          setIsSavingSupplierReturn(true);

          await api.products_UpdateInBulkItemsSupplierReturn2({
            toAllFilteredItems: allRowsSelected,
            ...(allRowsSelected
              ? {
                  ...mappedFilters,
                  pipelineStatuses: pipelineStatusFilter
                    ? [pipelineStatusFilter as unknown as EPipelineStatus]
                    : [],
                  statuses,
                  search,
                }
              : {
                  itemIds: selectionModel as string[],
                }),
          });

          onSuccess();
        } catch (error) {
          const errorMessages = formatApiValidationErrors(error);

          enqueueNotification(
            { content: errorMessages, title: content.submitItemSupplierReturnError },
            { variant: 'error' },
          );
        } finally {
          setIsSavingSupplierReturn(false);
        }
      };

      handleChangeItemSupplierReturnCallback();
    },
    [
      allRowsSelected,
      api,
      content.submitItemSupplierReturnError,
      enqueueNotification,
      mappedFilters,
      pipelineStatusFilter,
      search,
      selectionModel,
      statuses,
    ],
  );

  const handleChangeItemBusinessModel = useCallback(
    (businessModel: number, onSuccess: () => void) => {
      const handleChangeItemBusinessModelCallback = async () => {
        try {
          setIsSavingBusinessModel(true);

          const request: GetProductsBulkUpdateBusinessModelV3 = {
            itemBusinessModelId: businessModel,
            toAllFilteredItems: allRowsSelected,
            ...(allRowsSelected
              ? {
                  ...mappedFilters,
                  pipelineStatuses: pipelineStatusFilter
                    ? [pipelineStatusFilter as unknown as EPipelineStatus]
                    : [],
                  search,
                  statuses,
                }
              : {
                  itemIds: selectionModel as string[],
                }),
          };

          await api.products_UpdateItemBusinessModelV3(cleanObject(request));

          onSuccess();
          setSelectionModel([]);

          enqueueNotification(
            { title: content.changeItemBusinessModelSuccess },
            { variant: 'success' },
          );

          getItems();
        } catch {
          enqueueNotification(
            { title: content.changeItemBusinessModelError },
            { variant: 'error' },
          );
        } finally {
          setIsSavingBusinessModel(false);
        }
      };

      handleChangeItemBusinessModelCallback();
    },
    [
      allRowsSelected,
      api,
      content.changeItemBusinessModelError,
      content.changeItemBusinessModelSuccess,
      enqueueNotification,
      getItems,
      mappedFilters,
      pipelineStatusFilter,
      search,
      selectionModel,
      setSelectionModel,
      statuses,
    ],
  );

  const handleChangeItemTags = useCallback(
    (
      action: EBulkActionTags2,
      tags: IdNamePairGetModelOfNullableInteger[],
      onSuccess: () => void,
    ) => {
      const handleChangeItemTagsCallback = async () => {
        try {
          setIsSavingItemTags(action);

          const request: GetProductsBulkUpdateV2 = {
            action,
            actionTags: tags,
            toAllFilteredItems: allRowsSelected,
            ...(allRowsSelected
              ? {
                  ...mappedFilters,
                  pipelineStatuses: pipelineStatusFilter
                    ? [pipelineStatusFilter as unknown as EPipelineStatus]
                    : [],
                  search,
                  statuses,
                }
              : {
                  itemIds: selectionModel as string[],
                }),
          };

          await api.products_PutBulkEditTags2(cleanObject(request));

          onSuccess();
          setSelectionModel([]);

          enqueueNotification(
            {
              title:
                content[`changeItemTags${EBulkActionTags[action]}Success` as keyof typeof content],
            },
            { variant: 'success' },
          );

          getItemTagsOptions();
          getItems();
        } catch {
          enqueueNotification({ title: content.changeItemTagsError }, { variant: 'error' });
        } finally {
          setIsSavingItemTags(null);
        }
      };

      handleChangeItemTagsCallback();
    },
    [
      allRowsSelected,
      api,
      content,
      enqueueNotification,
      getItemTagsOptions,
      getItems,
      mappedFilters,
      pipelineStatusFilter,
      search,
      selectionModel,
      setSelectionModel,
      statuses,
    ],
  );

  const handleResetSupplierReturnValidation = useCallback(() => {
    setHasOnlyInvalidItems(true);
    setInvalidItems([]);
  }, []);

  return (
    <ItemListContext.Provider
      value={{
        bspTemplateFileURL,
        bspValidationUploadMessage,
        changeItemBusinessModel: handleChangeItemBusinessModel,
        changeItemSupplierReturn: handleChangeItemSupplierReturn,
        changeItemTags: handleChangeItemTags,
        counts,
        hasOnlyInvalidItems,
        items,
        invalidItems,
        isBspMassUpdateEnabled,
        isBspMassUpdateSubmitting,
        isBspMassUpdateValidated,
        isBspMassUpdateValidating,
        isSavingBusinessModel,
        isSavingItemTags,
        isSavingSupplierReturn,
        loading,
        onBspMassUpdateReset: handleBspMassUpdateReset,
        onBspMassUpdateSubmit: handleBspMassUpdateSubmit,
        onBspMassUpdateValidate: handleBspMassUpdateValidate,
        pipelineCounts,
        pipelineCounters,
        resetSupplierReturnValidation: handleResetSupplierReturnValidation,
        totalItems,
        validateItemSupplierReturn: handleValidateItemSupplierReturn,
      }}
    >
      {children}
    </ItemListContext.Provider>
  );
};

const ItemListConsumer = ItemListContext.Consumer;

export { ItemListConsumer, ItemListProvider };

export default ItemListContext;
