import { useMemo, useState } from 'react';
import { AppConfigurationClient } from '@azure/app-configuration';
import useAuth from './useAuth';

const client = new AppConfigurationClient(process.env.REACT_APP_FF_APP_CONFIGURATION || '');

const useFeatureFlag = (key: string) => {
  const { userProfile } = useAuth();

  const [enabled, setEnabled] = useState(false);

  useMemo(async () => {
    if (!key && !key.toString().trim().length) {
      console.error('A flag key is not correct.');
      return;
    }

    try {
      const result = await client.getConfigurationSetting({
        key: `.appconfig.featureflag/${key.toString().trim()}`,
      });

      if (result && typeof result === 'object') {
        const { enabled, conditions } = JSON.parse(result.value!);

        if (conditions?.client_filters?.length) {
          const targetFiltering = conditions.client_filters.find(
            ({ name }: { name: string }) => name === 'Microsoft.Targeting',
          );

          if (targetFiltering) {
            const { Users = [], Groups = [] } = targetFiltering?.parameters?.Audience;

            if (Groups.length) {
              const [, userDomain] = (userProfile.email as string).split('@');
              if (Groups.includes(`@${userDomain}`)) {
                setEnabled(true);
              }
            }

            if (Users.length && Users.includes(userProfile.email)) {
              setEnabled(true);
            }
          }
        } else {
          setEnabled(enabled);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [key, userProfile.email]);

  return enabled;
};

export default useFeatureFlag;
