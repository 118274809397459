import { lazy, Suspense, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { StyledLoading } from '@@components/loading-spinner/LoadingSpinner';
import { ItemsProvider } from '@@contexts/ItemsContext';
import { ItemsProviderOld } from '@@contexts/ItemsContextOld';
import useFeatureFlag from '@@hooks/useFeatureFlag';
import { ItemDetailsProvider } from '@@pages/item-details/contexts/ItemDetailsContext';
import { ItemListProvider } from '@@pages/item-list/contexts/ItemListContext';
import { ItemListProviderOld } from '@@pages/item-list/contexts/ItemListContextOld';

const PageProductList = lazy(() => import('@@pages/item-list/ItemList'));
const PageProductDetails = lazy(() => import('@@pages/item-details/ItemDetails'));

const PageProductListOld = lazy(() => import('@@pages/item-list/ItemListOld'));

const RouteInventory: React.FC = () => {
  const isStatusRevampEnabled = useFeatureFlag('EnableStatusRevamp');

  const Context = useMemo(
    () => (isStatusRevampEnabled ? ItemsProvider : ItemsProviderOld),
    [isStatusRevampEnabled],
  );

  return (
    <Suspense
      fallback={
        <Backdrop open={true} sx={{ zIndex: 99 }}>
          <StyledLoading />
        </Backdrop>
      }
    >
      <Context>
        <Routes>
          <Route
            path="/"
            element={
              isStatusRevampEnabled ? (
                <ItemListProvider>
                  <PageProductList />
                </ItemListProvider>
              ) : (
                <ItemListProviderOld>
                  <PageProductListOld />
                </ItemListProviderOld>
              )
            }
          />
          <Route
            path="/:id"
            element={
              <ItemDetailsProvider>
                <PageProductDetails />
              </ItemDetailsProvider>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Context>
    </Suspense>
  );
};

export default RouteInventory;
