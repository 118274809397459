import React from 'react';
import { css, styled, keyframes } from '@mui/material/styles';

const LuxSVGLogo: React.FC<{ className?: string }> = ({ className }) => (
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" className={className}>
    <path
      d="M13.0068 9.33331V14.2066V14.6733V20.02V25.3266V30.6666H18.3468V25.3266V20.02V14.6733V14.2066V9.33331H13.0068Z"
      fill="#131113"
    />
    <path d="M20.4941 30.6667H28.9941V25.3267L20.4941 30.6667Z" fill="#131113" />
    <circle cx="20" cy="20" r="18" />
  </svg>
);

export const StyledLoading = styled(LuxSVGLogo)(
  ({ theme }) => css`
    height: 60px;
    width: 60px;
    stroke: currentColor;
    margin-right: ${theme.space.xs};
    fill: none;

    & > * {
      fill: none;
    }

    & > :nth-child(1) {
      stroke-dashoffset: 55;
      stroke-dasharray: 55;
      animation: ${draw(55)} 1s infinite;
    }

    & > :nth-child(2) {
      stroke-dashoffset: 24;
      stroke-dasharray: 24;
      animation: ${draw(24)} 1s infinite;
    }

    & > :nth-child(3) {
      stroke-dashoffset: 60;
      stroke-dasharray: 114;
      animation: ${rotate} 1s linear infinite;
      transform-origin: center;
      stroke-width: 2;
    }
  `,
);

const rotate = keyframes`
  from {
    transform: rotate(0deg)
  }
  
  to { 
    transform: rotate(360deg)
  }
`;

const draw = (value: number) => keyframes`
  0%, 100% {
    stroke-dashoffset: 0;
  }
  
  50% {
    stroke-dashoffset: ${value};
  }
`;
