import { createContext, useEffect, useState } from 'react';
import { ReactFCC } from 'types/react';
import en from './content/en';

const LS_DEFAULT_LANG_KEY = 'default_lang';

type AvailableLanguages = 'en';

type ContentType = typeof en;

interface IContentContext {
  content: ContentType;
  lang: AvailableLanguages;
  setLang: (newLang: AvailableLanguages) => void;
}

const ContentContext = createContext<IContentContext>({
  content: en,
  lang: 'en',
  setLang: () => void 0,
});

const ContentProvider: ReactFCC = ({ children }) => {
  const [content, setContent] = useState<ContentType>(en);
  const [lang, setLang] = useState<AvailableLanguages>(
    (localStorage.getItem(LS_DEFAULT_LANG_KEY) as AvailableLanguages) || 'en',
  );

  useEffect(() => {
    const loadContent = () => {
      switch (lang) {
        case 'en':
          return import('./content/en');
        default:
          return;
      }
    };

    loadContent()?.then(({ default: newContent }) => setContent(newContent));
  }, [lang]);

  useEffect(() => {
    localStorage.setItem(LS_DEFAULT_LANG_KEY, lang);
  }, [lang]);

  return (
    <ContentContext.Provider value={{ content, lang, setLang }}>{children}</ContentContext.Provider>
  );
};

const ContentConsumer = ContentContext.Consumer;

export { ContentConsumer, ContentProvider };

export default ContentContext;
