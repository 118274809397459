import {
  InventoryGetCountersListModelV2,
  InventoryGetCountersPipelinesStatusModel,
} from '@inbound/api';

export type IItemAggregator = InventoryGetCountersListModelV2 & {
  outOf?: number;
  published?: number;
};

export interface IItemSubStatus {
  available: number;
  reserved: number;
  consigned: number;
  canceled: number;
  supplierReturn: number;
}

export interface IItemPipelineStatus
  extends Record<keyof InventoryGetCountersPipelinesStatusModel, number> {}

export type IItemCounts = IItemAggregator & IItemSubStatus & IItemPipelineStatus;

export const availableChangeCategoryStatusKeys = ['Draft', 'Ordered', 'Processing'];

export const itemStatusAggregators = [
  'allItems',
  'draft',
  'ordered',
  'processing',
  'processed',
  'published',
  'sold',
  'onHold',
  'outOf',
];

export const itemPublishedStatus = ['available', 'reserved', 'consigned'];

export const itemOutOfStatus = ['canceled', 'supplierReturn'];

export const pipelineStatus = ['productMatch', 'awaiting', 'review', 'pricing'];

export type TPipelineStatusPrioritizationKeys =
  | 'reProductMatch'
  | 'productMatchPriority'
  | 'newProduct'
  | 'nonPriority';
