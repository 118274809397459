import useAuth from '@@hooks/useAuth';

const SigninCallback: React.FC = () => {
  const { signinRedirect } = useAuth();

  signinRedirect();

  return null;
};

export default SigninCallback;
