import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import {
  EBulkActionTags,
  GetProductsBulkUpdate,
  GetProductsBulkUpdateBusinessModel,
  GetProductsQueryResponse,
  GetProductsRequest,
  IdNamePairGetModelOfNullableInteger,
  InventoryGetCountersListModel,
} from '@inbound/api';
import { ReactFCC } from 'types/react';
import { IDateRangeValue, IMultipleSelectOptionIdName, IValueRangeValue } from '@lux-ds/filter';
import { useNotification } from '@lux-ds/notification';
import useApi from '@@hooks/useApi';
import useContent from '@@hooks/useContent';
import useItemsContextOld from '@@hooks/useItemsContextOld';
import cleanObject from '@@utils/cleanObject';
import formatApiValidationErrors from '@@utils/formatApiValidationErrors';

interface IItemListContextOld {
  changeItemBusinessModel: (businessModel: number, onSuccess: () => void) => void;
  changeItemSupplierReturn: (onSuccess: () => void) => void;
  changeItemTags: (
    action: EBulkActionTags,
    tags: IdNamePairGetModelOfNullableInteger[],
    onSuccess: () => void,
  ) => void;
  counts: InventoryGetCountersListModel;
  hasOnlyInvalidItems: boolean;
  isSavingBusinessModel: boolean;
  isSavingItemTags: EBulkActionTags | null;
  isSavingSupplierReturn: boolean;
  items: GetProductsQueryResponse[];
  loading: boolean;
  invalidItems: string[];
  resetSupplierReturnValidation: () => void;
  totalItems: number;
  validateItemSupplierReturn: () => void;
}

const countsDefaultValue: InventoryGetCountersListModel = {
  allItems: 0,
  productMatch: 0,
  inTransit: 0,
  delivered: 0,
  pendingProcessing: 0,
  processing: 0,
  awaiting: 0,
  pendingReview: 0,
  reviewed: 0,
  available: 0,
  disposedOf: 0,
  canceled: 0,
  supplierReturn: 0,
  soldBySupplier: 0,
  published: 0,
  unpublished: 0,
  sold: 0,
  reserved: 0,
  marketplace: 0,
  consigned: 0,
  draft: 0,
  pendingPricing: 0,
};

const ItemListContextOld = createContext<IItemListContextOld>({
  changeItemBusinessModel: () => void 0,
  changeItemSupplierReturn: () => void 0,
  changeItemTags: () => void 0,
  counts: countsDefaultValue,
  hasOnlyInvalidItems: true,
  isSavingBusinessModel: false,
  isSavingItemTags: null,
  isSavingSupplierReturn: false,
  items: [],
  loading: true,
  invalidItems: [],
  resetSupplierReturnValidation: () => void 0,
  totalItems: 0,
  validateItemSupplierReturn: () => void 0,
});

const ItemListProviderOld: ReactFCC = ({ children }) => {
  const api = useApi();
  const {
    content: { itemList: content },
  } = useContent();
  const {
    allRowsSelected,
    filters,
    getFilterOptions,
    getItemTagsOptions,
    page,
    pageSize,
    quickFilter,
    search,
    selectionModel,
    setPage,
    setSelectionModel,
    sortModel,
  } = useItemsContextOld();
  const { enqueueNotification } = useNotification();
  const [abortControllerGetItems, setAbortControllerGetItems] = useState<AbortController | null>(
    null,
  );
  const [counts, setCounts] = useState<IItemListContextOld['counts']>(countsDefaultValue);

  const [hasOnlyInvalidItems, setHasOnlyInvalidItems] =
    useState<IItemListContextOld['hasOnlyInvalidItems']>(true);
  const [isSavingBusinessModel, setIsSavingBusinessModel] =
    useState<IItemListContextOld['isSavingBusinessModel']>(false);
  const [isSavingSupplierReturn, setIsSavingSupplierReturn] =
    useState<IItemListContextOld['isSavingBusinessModel']>(false);
  const [isSavingItemTags, setIsSavingItemTags] =
    useState<IItemListContextOld['isSavingItemTags']>(null);
  const [items, setItems] = useState<IItemListContextOld['items']>([]);
  const [loading, setLoading] = useState<IItemListContextOld['loading']>(true);
  const [invalidItems, setInvalidItems] = useState<IItemListContextOld['invalidItems']>([]);
  const [totalItems, setTotalItems] = useState<IItemListContextOld['totalItems']>(0);

  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);

  const mappedFilters = useMemo(
    () =>
      Object.entries(filters).reduce(
        (
          result: Record<
            string,
            IDateRangeValue | IValueRangeValue | IMultipleSelectOptionIdName['id'][]
          >,
          [key, values],
        ) => ({
          ...result,
          [key]: Array.isArray(values)
            ? values.map(value => ('id' in value ? value.id : value.key))
            : values,
        }),
        {},
      ),
    [filters],
  );

  useEffect(() => {
    const getItemsCallback = async () => {
      try {
        setLoading(true);

        const mappedFilters = Object.entries(filters).reduce(
          (result, [key, values]) => ({
            ...result,
            [key]: Array.isArray(values)
              ? values.map(value => ('id' in value ? value.id : value.key))
              : values,
          }),
          {},
        );

        let { field: orderBy, sort: orderDirection } = sortModel[0] || {};

        if (!sortModel[0]) {
          if (quickFilter === 'pendingReview') {
            orderBy = 'isRereview';
            orderDirection = 'desc';
          } else if (quickFilter === 'productMatch') {
            orderBy = 'isProductMatchPriority';
            orderDirection = 'desc';
          }
        }

        const request: GetProductsRequest = {
          ...mappedFilters,
          orderBy,
          orderDirection: orderDirection || undefined,
          pageIndex: page,
          pageSize,
          quickFilter,
          search,
        };

        const {
          counts: currentCounts,
          items,
          totalItems: currentItems,
        } = await api.products_GetAll(cleanObject(request), abortControllerGetItems?.signal);

        setCounts(currentCounts || countsDefaultValue);
        setItems(items || []);
        setTotalItems(currentItems || 0);
        setLoading(false);
      } catch (error) {
        if ((error as DOMException).name !== 'AbortError') {
          setItems([]);
          setPage(0);
          setTotalItems(0);
          setLoading(false);

          enqueueNotification({ title: content.errorGetItems }, { variant: 'error' });
        }
      }
    };

    if (abortControllerGetItems) {
      getItemsCallback();
    }

    return () => abortControllerGetItems?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abortControllerGetItems, api, content, enqueueNotification, setPage]);

  const getItems = useCallback(() => setAbortControllerGetItems(new AbortController()), []);

  useEffect(() => {
    getItems();
  }, [getItems, mappedFilters, page, pageSize, quickFilter, search, sortModel]);

  const handleValidateItemSupplierReturn = useCallback(() => {
    const handleValidateItemSupplierReturnCallback = async () => {
      try {
        const { hasOnlyInvalidItems, invalidItems } =
          await api.products_UpdateInBulkItemsSupplierReturn({
            toAllFilteredItems: allRowsSelected,
            toValidateItems: true,
            ...(allRowsSelected
              ? {
                  ...mappedFilters,
                  quickFilter,
                  search,
                }
              : {
                  itemIds: selectionModel as string[],
                }),
          });

        if (invalidItems?.length) {
          const messages = invalidItems?.map(({ sku }) => (sku as string).replace('\n', '')) || [];
          setInvalidItems(messages);
        }

        setHasOnlyInvalidItems(!!hasOnlyInvalidItems);
      } catch (error) {
        const errorMessages = formatApiValidationErrors(error);

        enqueueNotification(
          { content: errorMessages, title: content.validateItemSupplierReturnError },
          { variant: 'error' },
        );
      } finally {
        setIsSavingSupplierReturn(false);
      }
    };

    handleValidateItemSupplierReturnCallback();
  }, [
    allRowsSelected,
    api,
    content.validateItemSupplierReturnError,
    enqueueNotification,
    mappedFilters,
    quickFilter,
    search,
    selectionModel,
  ]);

  const handleChangeItemSupplierReturn = useCallback(
    (onSuccess: () => void) => {
      const handleChangeItemSupplierReturnCallback = async () => {
        try {
          setIsSavingSupplierReturn(true);

          await api.products_UpdateInBulkItemsSupplierReturn({
            toAllFilteredItems: allRowsSelected,
            ...(allRowsSelected
              ? {
                  ...mappedFilters,
                  quickFilter,
                  search,
                }
              : {
                  itemIds: selectionModel as string[],
                }),
          });

          onSuccess();
        } catch (error) {
          const errorMessages = formatApiValidationErrors(error);

          enqueueNotification(
            { content: errorMessages, title: content.submitItemSupplierReturnError },
            { variant: 'error' },
          );
        } finally {
          setIsSavingSupplierReturn(false);
        }
      };

      handleChangeItemSupplierReturnCallback();
    },
    [
      allRowsSelected,
      api,
      content.submitItemSupplierReturnError,
      enqueueNotification,
      mappedFilters,
      quickFilter,
      search,
      selectionModel,
    ],
  );

  const handleChangeItemBusinessModel = useCallback(
    (businessModel: number, onSuccess: () => void) => {
      const handleChangeItemBusinessModelCallback = async () => {
        try {
          setIsSavingBusinessModel(true);

          const request: GetProductsBulkUpdateBusinessModel = {
            itemBusinessModelId: businessModel,
            toAllFilteredItems: allRowsSelected,
            ...(allRowsSelected
              ? {
                  ...mappedFilters,
                  quickFilter,
                  search,
                }
              : {
                  itemIds: selectionModel as string[],
                }),
          };

          await api.products_UpdateItemBusinessModelV2(cleanObject(request));

          onSuccess();
          setSelectionModel([]);

          enqueueNotification(
            { title: content.changeItemBusinessModelSuccess },
            { variant: 'success' },
          );

          getItems();
        } catch {
          enqueueNotification(
            { title: content.changeItemBusinessModelError },
            { variant: 'error' },
          );
        } finally {
          setIsSavingBusinessModel(false);
        }
      };

      handleChangeItemBusinessModelCallback();
    },
    [
      allRowsSelected,
      api,
      content,
      enqueueNotification,
      getItems,
      mappedFilters,
      quickFilter,
      search,
      selectionModel,
      setSelectionModel,
    ],
  );

  const handleChangeItemTags = useCallback(
    (
      action: EBulkActionTags,
      tags: IdNamePairGetModelOfNullableInteger[],
      onSuccess: () => void,
    ) => {
      const handleChangeItemTagsCallback = async () => {
        try {
          setIsSavingItemTags(action);

          const request: GetProductsBulkUpdate = {
            action,
            actionTags: tags,
            toAllFilteredItems: allRowsSelected,
            ...(allRowsSelected
              ? {
                  ...mappedFilters,
                  quickFilter,
                  search,
                }
              : {
                  itemIds: selectionModel as string[],
                }),
          };

          await api.products_PutBulkEditTags(cleanObject(request));

          onSuccess();
          setSelectionModel([]);

          enqueueNotification(
            {
              title:
                content[`changeItemTags${EBulkActionTags[action]}Success` as keyof typeof content],
            },
            { variant: 'success' },
          );

          getItemTagsOptions();
          getItems();
        } catch {
          enqueueNotification({ title: content.changeItemTagsError }, { variant: 'error' });
        } finally {
          setIsSavingItemTags(null);
        }
      };

      handleChangeItemTagsCallback();
    },
    [
      allRowsSelected,
      api,
      content,
      enqueueNotification,
      getItemTagsOptions,
      getItems,
      mappedFilters,
      quickFilter,
      search,
      selectionModel,
      setSelectionModel,
    ],
  );

  const handleResetSupplierReturnValidation = useCallback(() => {
    setHasOnlyInvalidItems(true);
    setInvalidItems([]);
  }, []);

  return (
    <ItemListContextOld.Provider
      value={{
        changeItemBusinessModel: handleChangeItemBusinessModel,
        changeItemSupplierReturn: handleChangeItemSupplierReturn,
        changeItemTags: handleChangeItemTags,
        counts,
        hasOnlyInvalidItems,
        items,
        invalidItems,
        isSavingBusinessModel,
        isSavingItemTags,
        isSavingSupplierReturn,
        loading,
        resetSupplierReturnValidation: handleResetSupplierReturnValidation,
        totalItems,
        validateItemSupplierReturn: handleValidateItemSupplierReturn,
      }}
    >
      {children}
    </ItemListContextOld.Provider>
  );
};

const ItemListConsumer = ItemListContextOld.Consumer;

export { ItemListConsumer, ItemListProviderOld };

export default ItemListContextOld;
